import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseTopNav from '@/components/base/baseTopNav';
import TradingHistoryDetailButton from './components/TradingHistoryDetailButton';
import TradingHistoryDetailInfo from './components/TradingHistoryDetailInfo';
import TradingHistoryDetailTitle from './components/TradingHistoryDetailTitle';
import { useOrderHistoryDetailStateHooks } from './useStateHooks';

export default () => {
  const { data, type, loading, statusRecord } = useOrderHistoryDetailStateHooks();
  return (
    <div>
      <BaseTopNav></BaseTopNav>
      <BaseLoadingDisplay loading={loading} list={loading ? [] : [data]}>
        <div className=" p-4 min-h-6">
          {/* 详情标题 */}
          <div className=" mb-4">
            <TradingHistoryDetailTitle data={data} statusRecord={statusRecord}></TradingHistoryDetailTitle>
          </div>
          {/* 详情信息 */}
          <div>
            <TradingHistoryDetailInfo
              data={data}
              type={type}
            ></TradingHistoryDetailInfo>
          </div>
        </div>

        {/* 底部按钮 */}
        <TradingHistoryDetailButton
          data={data}
          type={type}
        ></TradingHistoryDetailButton>
      </BaseLoadingDisplay>
    </div>
  );
};
