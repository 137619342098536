import { useTranslation } from 'react-i18next';
import { useOrderHistoryDetailStateHooks } from '../useStateHooks';

export default ({ data,statusRecord }: any) => {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-col items-center gap-2">
      {/* <div className="font-bold text-2xl">
        {RenderUtil.FormatAmount(data?.amount)}
      </div> */}
      <div className="flex gap-2 font-bold text-2xl">
        {statusRecord?.find((i) => {
          return i.value === data?.status?.toString();
        })?.text || ''}
      </div>
    </div>
  );
};
