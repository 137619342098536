import {
  CompletedOrderOrderProcessEnum,
  CompletedOrderOrderStatusEnum,
} from '@/enums/businessEnum';
import { rechargeDetail, withdrawDetail } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'umi';

export const useOrderHistoryDetailStateHooks = () => {
  const { t } = useTranslation();
  const { state,target }: any = useLocation();
  const { id, type } = state;
  const statusRecord = [
    {
      text: t('审核中'),
      value: CompletedOrderOrderStatusEnum.UnderReview,
    },
    {
      text: t('未通过'),
      value: CompletedOrderOrderStatusEnum.DID_NOT_PASS,
    },
    {
      text: t('已通过'),
      value: CompletedOrderOrderStatusEnum.COMPLETED,
    },
    {
      text: t('已取消'),
      value: CompletedOrderOrderStatusEnum.CANCELLED,
    },
  ];

  const { data, loading } = useReq(
    () => {
      if (type === +CompletedOrderOrderProcessEnum.SUB) {
        return withdrawDetail({ id });
      }
      if (type === +CompletedOrderOrderProcessEnum.ADD) {
        return rechargeDetail({ id });
      }
    },
    {
      ready: !!id,
      formatResult: (res: any) => {
        return res?.data ?? {};
      },
      staleTime: 1000 * 2,
    },
  );

  return { data, statusRecord, type, loading ,target};
};
