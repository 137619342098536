import { F7ExclamationmarkCircleFill } from '@/assets/icons/comm/F7ExclamationmarkCircleFill';
import { BxsChevronRight } from '@/assets/icons/funds/BxsChevronRight';
import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import { SolarDocumentTextBold } from '@/assets/icons/pcHome/SolarDocumentTextBold';
import {
  CompletedOrderOrderProcessEnum,
  CompletedOrderOrderStatusEnum,
  CustomerServiceTypeEnum, RechargeChannelTypeEnum,
} from '@/enums/businessEnum';
import RenderUtil from '@/utils/RenderUtil';
import { FormatUtils } from '@/utils/format';
import { Divider, Tooltip } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useModel } from '@@/exports';

export default ({ data, type, target }: any) => {
  const { t } = useTranslation();
  const { gotoC2CCustomerService, gotoCustomerService } = useModel('imSocket');

  return (
    <div>
      <div className="p-4 border-1 border-backgroundAuxiliaryColor rounded-md flex flex-col gap-4 text-sm">
        <div className=" flex justify-between items-center">
          <div className=" text-base">
            {type === +CompletedOrderOrderProcessEnum.ADD && (
              <div className=" text-successColor font-bold">{t('充值')}</div>
            )}
            {type === +CompletedOrderOrderProcessEnum.SUB && (
              <div className=" text-errorColor font-bold">{t('提现')}</div>
            )}
          </div>
          <div className="  text-xl">
            <SolarDocumentTextBold></SolarDocumentTextBold>
          </div>
        </div>
        {type === +CompletedOrderOrderProcessEnum.SUB && (
          <div className=" flex justify-between items-center">
            <div className=" text-auxiliaryTextColor flex items-center">
              {t('币种')}
            </div>
            <div className=" font-bold">{data?.coinName}</div>
          </div>
        )}
        <div className=" flex justify-between items-center">
          <div className=" text-auxiliaryTextColor flex items-center">
            {t('总额')}
            <Tooltip content="I am a tooltip">
              <F7ExclamationmarkCircleFill></F7ExclamationmarkCircleFill>
            </Tooltip>
          </div>
          <div className=" font-bold">
            {RenderUtil.FormatAmount(data?.amount, 8)}
          </div>
        </div>

        {type === +CompletedOrderOrderProcessEnum.SUB && (
          <div className=" flex justify-between items-center">
            <div className=" text-auxiliaryTextColor flex items-center">
              {t('手续费')}
            </div>
            <div className=" font-bold">
              {RenderUtil.FormatAmount(data?.fee, 8)}
            </div>
          </div>
        )}
        {type === +CompletedOrderOrderProcessEnum.SUB && (
          <div className=" flex justify-between items-center">
            <div className=" text-auxiliaryTextColor flex items-center">
              {t('实际到账金额')}
            </div>
            <div className=" font-bold">
              {RenderUtil.FormatAmount(data?.actualAmount, 8)}
            </div>
          </div>
        )}
        {/*  未通过 */}
        {
          data?.status?.toString() === CompletedOrderOrderStatusEnum.DID_NOT_PASS &&
          <>
            <div className="w-full flex flex-wrap justify-between items-center">
              <div className="w-full text-auxiliaryTextColor flex items-center">
                {t('备注')}
              </div>
              <div className="mt-1 break-all whitespace-pre-wrap font-bold">
                {data?.remark ? data?.remark : t('暂无信息')}
              </div>
            </div>
          </>
        }
        <Divider className="my-4 !bg-backgroundAuxiliaryColor" />
        {data?.code && (
          <div className=" flex justify-between items-center">
            <div className=" text-auxiliaryTextColor">{t('订单号')}</div>
            <div className=" text-auxiliaryTextColor font-bold flex items-center">
              {data?.code}
              <PhCopyFill
                onClick={() => FormatUtils.copyText(data?.code || '')}
                className="text-auxiliaryTextColor ml-2"
              />
            </div>
          </div>
        )}

        <div className=" flex justify-between items-center">
          <div className=" text-auxiliaryTextColor">{t('创建时间')}</div>
          <div className=" text-auxiliaryTextColor font-bold">
            {RenderUtil.formatDate(data?.createTime, 'YYYY-MM-DD HH:mm:ss')}
          </div>
        </div>
      </div>
      <div
        className=" flex items-center justify-between mt-4 font-bold"
        onClick={() => {
          if (type === +CompletedOrderOrderProcessEnum.ADD && data?.channelId) {
            gotoC2CCustomerService({
              groupType:data?.rechargeType,
              channelId: data?.channelId,
              rechargeType: data?.rechargeType,
            });
            return;
          }
          gotoCustomerService();

        }}
      >
        <div>{t('对订单有疑问')}</div>
        <BxsChevronRight></BxsChevronRight>
      </div>
    </div>
  );
};
